<template>
  <div class="edit-data">
    <BCard>
      <div class="mb-[24px]">
        <strong class="text-2xl text-[#333]">Edit Pendamping UMKM</strong>
      </div>
      <div class="mb-[24px]">
        <div class="form-link">
          <label for="link">Link</label>
          <BInputGroup class="mb-[24px]">
            <template #append>
              <b-input-group-text style="border-left: 0">
                <span
                  :style="link_pumkm === '' || !isLinkPumkm ? 'border: none; color:#C2C2C2' : 'color:#08A0F7'"
                >Link</span>
              </b-input-group-text>
            </template>
            <BFormInput
              id="input-small"
              v-model="link_pumkm"
              style="border-right: 0"
              class="form-control-link text-primary"
              @input="validateLinkPumkm"
            />
          </BInputGroup>
        </div>
        <BRow class="align-items-center">
          <BCol lg="6">
            <label for="email">Email</label>
            <BFormInput
              v-model="email"
              class="mb-[24px]"
              disabled
            />
          </BCol>
          <BCol lg="6">
            <label for="name">Nama Lengkap</label>
            <BFormInput
              v-model="full_name"
              class="mb-[24px]"
              disabled
            />
          </BCol>
          <BCol lg="6">
            <label for="phone">Nomor Telepon</label>
            <b-input-group class="mb-[24px]">
              <template #prepend>
                <b-input-group-text style="background:#efefef;border-right:0;padding-right:0">
                  <span class="me-2">+62</span>
                  <span>|</span>
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="phone_no"
                style="border-left:0;padding-left:8px"
                disabled
              />
            </b-input-group>
          </BCol>
          <BCol lg="6">
            <label for="city">Kota / Kabupaten *</label>
            <v-select
              v-model="cityName"
              :value="cityName"
              :options="options"
              placeholder="Pilih Kota"
              class="form-address mb-[24px]"
              @search="search => (search.length > 0 ? debounceCity(search) : '')"
            >
              <template #no-options>
                Kota yang kamu cari tidak ada
              </template>
            </v-select>
          </BCol>
        </BRow>
        <div class="align-items-center mb-[24px]">
          <div>
            <label for="photo">Foto *</label>
            <b-input-group>
              <b-input-group-append
                class="d-flex align-items-center w-100"
                style="
                    border-width: 1px;
                    border-radius: 5px;
                    border-color: #cfcfcf;
                  "
                @click="$refs.photoFile.click()"
              >
                <BButton
                  class="d-flex align-items-center w-40 btn-upload"
                  variant="warning"
                  size="sm"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/document-upload.svg"
                    alt=""
                    width="15px"
                  >
                  <span
                    class="ml-[5px]"
                    style="font-size: 12px"
                  >
                    Pilih Foto</span>
                </BButton>
                <b-form-input
                  id="photoFile"
                  ref="photoFile"
                  v-model="photo_upload"
                  class="text-file-foto"
                  :readonly="true"
                  :placeholder="photo_upload === null? photo_name: photo_upload"
                />
              </b-input-group-append>
              <input
                id="photoFile"
                ref="photoFile"
                class="form-control"
                type="file"
                style="display: none"
                @change="validateExt"
              >
            </b-input-group>
            <small
              res="err-mess"
              class="text-black err-ok"
            >Upload foto dengan maksimal 1 Mb</small>
          </div>
        </div>
        <div class="mb-[20px]">
          <strong class="text-xl text-[#333]">Social Media</strong>
        </div>
        <BRow class="align-item-center">
          <BCol
            lg="6"
            class="d-flex align-item-center mb-[24px]"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/Outline-Facebook.svg"
              width="25px"
              class="me-2"
            >
            <BFormInput
              v-model="fb_account"
              :value="fb_account"
              placeholder="Your Facebook"
            />
          </BCol>
          <BCol
            lg="6"
            class="d-flex align-item-center mb-[24px]"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/Outline-Twitter.svg"
              width="25px"
              class="me-2"
            >
            <BFormInput
              v-model="tw_account"
              :value="tw_account"
              placeholder="Your Twitter"
            />
          </BCol>
          <BCol
            lg="6"
            class="d-flex align-item-center mb-[24px]"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/Outline-Instagram.svg"
              width="25px"
              class="me-2"
            >
            <BFormInput
              v-model="ig_account"
              :value="ig_account"
              placeholder="Your Instagram"
            />
          </BCol>
          <BCol
            lg="6"
            class="d-flex align-item-center mb-[24px]"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/Outline-Telegram.svg"
              width="25px"
              class="me-2"
            >
            <BFormInput
              v-model="tg_account"
              :value="tg_account"
              placeholder="Your Telegram"
            />
          </BCol>
          <BCol
            lg="6"
            class="d-flex align-item-center mb-[24px]"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/Outline-tiktok.svg"
              width="25px"
              class="me-2"
            >
            <BFormInput
              v-model="tk_account"
              :value="tk_account"
              placeholder="Your TikTok"
            />
          </BCol>
        </BRow>
        <div class="align-item-center">
          <div class="mb-[20px]">
            <strong class="text-xl text-[#333]">Keahlian *</strong>
          </div>
          <b-form-textarea
            id="textarea"
            v-model="skill_set"
            :value="skill_set"
            no-resize
            placeholder="Contoh: Digital Marketing (Facebook Ads, Instagram Ads, Tiktok Ads, SEO & SEM, dll), Perencanaan Keuangan, Izin Usaha, Sertifikasi Halal Produk, dll"
          />
        </div>
      </div>
      <BRow class="justify-content-end">
        <BCol lg="2">
          <b-button
            variant="outline-primary"
            block
            class="mb-[24px]"
            @click="backToPrev"
          >
            Kembali
          </b-button>
        </BCol>
        <BCol lg="2">
          <b-button
            :variant="link_pumkm === '' || !isLinkPumkm || isPhotoOke ? 'secondary' : 'primary'"
            block
            class="mb-[24px]"
            :disabled="link_pumkm === '' || !isLinkPumkm || isPhotoOke"
            @click="confirmEditData"
          >
            Simpan
          </b-button>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<style lang="scss" scoped>
@import '../Index.scss';
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<script>
import { affiliateAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { alertError, alertSuccess } from '@toast'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      alertError,
      alertSuccess,
      options: [],
      isLinkPumkm: true,
      loading: true,
      getId: 0,
      full_name: '',
      email: '',
      phone_no: '',
      cityName: '',
      link_photo: null,
      photo_upload: null,
      isMsgPhoto: '',
      isErrPhoto: false,
      isPhotoOke: false,
      photo_name: '',
      link_pumkm: '',
      skill_set: '',
      fb_account: '',
      ig_account: '',
      tk_account: '',
      tw_account: '',
      tg_account: '',
      isAffiliate: 0,
    }
  },
  mounted() {
    this.getDataEdit()
  },
  methods: {
    getDataEdit() {
      const valAff = this.$route.params.affiliator_id
      if (valAff === 0) {
        this.isAffiliate = 0
      } else {
        this.isAffiliate = 1
      }
      const params = {
        pumkm_id: this.$route.params.pumkm_id,
        affiliator_id: this.$route.params.affiliator_id,
        is_affiliate: this.isAffiliate,
      }
      const url = '/v1/pumkm/admin/check-insert-pumkm'
      affiliateAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.dataEdit = data
          const phoneNumber = data.phone_number
          if (phoneNumber.startsWith('62')) {
            this.phone_no = phoneNumber.substring(2)
          } else if (phoneNumber.startsWith('0')) {
            this.phone_no = phoneNumber.substring(1)
          }
          localStorage.setItem('Kota', JSON.stringify(this.dataEdit.city))
          this.loadForm()
          this.loading = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            { timeout: 3000 },
          )
          this.loading = false
        })
    },
    loadForm() {
      this.getId = this.dataEdit.id_pumkm
      this.full_name = this.dataEdit.name
      this.email = this.dataEdit.email
      // this.phone_no = this.dataEdit.phone_number
      this.link_pumkm = this.dataEdit.link_pumkm
      this.cityName = this.dataEdit.city
      this.link_photo = this.dataEdit.link_photo
      this.photo_name = this.dataEdit.name_photo
      this.skill_set = this.dataEdit.skill_set
      this.fb_account = this.dataEdit.fb_account
      this.ig_account = this.dataEdit.ig_account
      this.tg_account = this.dataEdit.tg_account
      this.tk_account = this.dataEdit.tk_account
      this.tw_account = this.dataEdit.tw_account
    },
    debounceCity(value) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.getCity(value)
      }, 1000)
    },
    async getCity(value) {
      const url = '/v1/register/search-city'
      await affiliateAxiosIns
        .get(url, {
          params: {
            search: value,
          },
        })
        .then(res => {
          const { data } = res.data
          this.options = data.map(city => ({
            label: `${city.city_name}, ${city.province_name}`,
            value: city.city_name,
          }))
        })
    },
    validateLinkPumkm() {
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/
      this.isLinkPumkm = urlPattern.test(this.link_pumkm)
    },
    validateExt(evChange) {
      const file = evChange.target.value
      const fileExtension = file.substr(file.lastIndexOf('.') + 1)
      if (
        fileExtension !== 'jpg'
        && fileExtension !== 'jpeg'
        && fileExtension !== 'png'
        && fileExtension !== 'gif'
      ) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Galat',
            text: 'Hanya dapat upload file dengan ekstensi JPG, PNG, JPEG and GIF',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        const errorSpan = document.querySelector('span[res="err-mess"]')
        if (errorSpan) {
          errorSpan.classList.remove('err-err')
          errorSpan.classList.add('err-ok')
        }
        this.$refs.photoFile.files = null
        this.photo_upload = null
      } else {
        this.validateSize()
      }
    },
    validateSize() {
      const maxSizeInBytes = 1024 * 1024
      const fileSize = this.$refs.photoFile.files[0]?.size || 0
      if (fileSize > maxSizeInBytes) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Galat',
            text: 'File tidak boleh lebih dari 1Mb',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        const errorSpan = document.querySelector('span[res="err-mess"]')
        if (errorSpan) {
          errorSpan.classList.remove('err-ok')
          errorSpan.classList.add('err-err')
        }
        this.$refs.photoFile.files = null
        this.photo_upload = null
        this.isPhotoOke = true
        return false
      }
      const errorSpan = document.querySelector('span[res="err-mess"]')
      if (errorSpan) {
        errorSpan.classList.remove('err-err')
        errorSpan.classList.add('err-ok')
      }
      this.onFileInputChange()
      return true
    },
    onFileInputChange() {
      const fileInput = this.$refs.photoFile.files
      if (fileInput.length > 0) {
        this.photo_upload = fileInput[0].name
        this.isPhotoOke = false
        const errorSpan = document.querySelector('span[res="err-mess"]')
        if (errorSpan) {
          errorSpan.classList.remove('err-err')
          errorSpan.classList.add('err-ok')
        }
      } else {
        this.$refs.photoFile.files = null
        this.photo_upload = null
        this.isPhotoOke = true
      }
    },
    confirmEditData() {
      const kota = JSON.parse(localStorage.getItem('Kota'))
      const formData = new FormData()
      formData.append('pumkm_id', this.getId)
      formData.append('link_pumkm', this.link_pumkm)
      formData.append('full_name', this.full_name === '' ? '' : this.full_name)
      formData.append('city', this.cityName === kota ? kota : this.cityName.label)
      formData.append(
        'photo',
        this.$refs.photoFile.files[0] === undefined
          ? ''
          : this.$refs.photoFile.files[0],
      )
      formData.append('fb', this.fb_account)
      formData.append('ig', this.ig_account)
      formData.append('tw', this.tw_account)
      formData.append('tg', this.tg_account)
      formData.append('ttk', this.tk_account)
      formData.append('skill', this.skill_set)
      this.$bvModal.hide('modal-edit')
      this.loading = true
      affiliateAxiosIns.put('/v1/pumkm/admin/update-pumkm', formData).then(res => {
        this.loading = false
        const text = 'Data PUMKM yang anda Edit Berhasil'
        this.alertSuccess(text)
        window.history.go(-1)
      }).catch(err => {
        const text = 'Data PUMKM yang anda Edit Gagal'
        this.alertError(text)
      })
    },
    backToPrev() {
      window.history.go(-1)
    },
  },
}
</script>
